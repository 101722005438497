export async function hash(
  algorithm: 'SHA-1' | 'SHA-256' | 'SHA-512',
  message: string,
) {
  return new Uint8Array(
    await crypto.subtle.digest(algorithm, new TextEncoder().encode(message)),
  );
}

export async function hashAsString(
  algorithm: 'SHA-1' | 'SHA-256' | 'SHA-512',
  message: string,
) {
  return new TextDecoder().decode(await hash(algorithm, message));
}

export async function hashAsB64(
  algorithm: 'SHA-1' | 'SHA-256' | 'SHA-512',
  message: string,
) {
  return btoa(String.fromCharCode(...(await hash(algorithm, message))));
}

const RANDOM_CHARS =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
export function generateRandomString(length: number) {
  const charactersLength = RANDOM_CHARS.length;
  const randomValues = new Uint32Array(length);
  crypto.getRandomValues(randomValues);
  let result = '';
  for (let i = 0; i < length; i++) {
    result += RANDOM_CHARS.charAt(randomValues[i] % charactersLength);
  }
  return result;
}
