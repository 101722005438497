class Node<T> {
  value: T;
  next!: Node<T>;

  constructor(value: T) {
    this.value = value;
  }
}

export class Queue<T> {
  #head!: Node<T> | undefined;
  #tail!: Node<T> | undefined;
  #size!: number;

  constructor() {
    this.clear();
  }

  enqueue(value: T) {
    const node = new Node(value);

    if (this.#head) {
      if (this.#tail?.next) {
        this.#tail.next = node;
      }
      this.#tail = node;
    } else {
      this.#head = node;
      this.#tail = node;
    }

    this.#size++;
  }

  dequeue(): T | undefined {
    const current = this.#head;
    if (!current) {
      return;
    }

    this.#head = this.#head?.next;
    this.#size--;
    return current.value;
  }

  clear() {
    this.#head = undefined;
    this.#tail = undefined;
    this.#size = 0;
  }

  get size() {
    return this.#size;
  }

  *[Symbol.iterator]() {
    let current = this.#head;

    while (current) {
      yield current.value;
      current = current.next;
    }
  }
}
