const ISO8601_REGEX = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/;

export class InputValidationError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'InputValidationError';
  }
}

export function isISO8601Date(strDate: string) {
  if (!ISO8601_REGEX.test(strDate)) {
    return false;
  }
  const d = new Date(strDate);
  return !Number.isNaN(d.getTime()) && d.toISOString() === strDate;
}

export function isValidEmail(Email: string): boolean {
  return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(Email);
}
