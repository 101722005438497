import * as E from 'fp-ts/lib/Either';
import * as TE from 'fp-ts/lib/TaskEither';
import * as F from 'fp-ts/lib/function';
import * as jose from 'jose';
import type { JWTVerifyOptions } from 'jose';
import { isAsNotEmptyRecord } from './type';

const ERR_JWKS_MULTIPLE_MATCHING_KEYS = 'ERR_JWKS_MULTIPLE_MATCHING_KEYS';
const ERR_JWS_SIGNATURE_VERIFICATION_FAILED =
  'ERR_JWS_SIGNATURE_VERIFICATION_FAILED';

export interface MakeJwtRemoteValidatorParams {
  url: URL | string;
  issuer?: string;
  audience?: string;
  options?: JWTVerifyOptions;
}
// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export function makeJwtRemoteValidator<T = any>({
  url,
  issuer,
  audience,
  options,
}: MakeJwtRemoteValidatorParams): (jwt: string) => Promise<E.Either<Error, T>> {
  const JWKS = jose.createRemoteJWKSet(url instanceof URL ? url : new URL(url));

  return function validateJwt(jwt: string): Promise<E.Either<Error, T>> {
    const verify = () =>
      jose
        .jwtVerify(jwt, JWKS, {
          issuer,
          audience,
        })
        .catch(async (error) => {
          if (error?.code === ERR_JWKS_MULTIPLE_MATCHING_KEYS) {
            for await (const publicKey of error) {
              try {
                return (await jose.jwtVerify(jwt, publicKey, options)) as T;
              } catch (innerError: unknown) {
                if (
                  isAsNotEmptyRecord(innerError) &&
                  innerError?.code === ERR_JWS_SIGNATURE_VERIFICATION_FAILED
                ) {
                  continue;
                }

                throw innerError;
              }
            }
            throw new jose.errors.JWSSignatureVerificationFailed();
          }
          throw error;
        });

    return F.pipe(TE.tryCatch(verify, E.toError))() as Promise<
      E.Either<Error, T>
    >;
  };
}

export type JWTVerifyResult = jose.JWTVerifyResult;

export function getJWTValues(jwt: string) {
  if (!jwt) return null;
  function parseJwt(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => {
          return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
        })
        .join(''),
    );

    return JSON.parse(jsonPayload);
  }

  return parseJwt(jwt);
}

export async function signJWTHS256(
  // biome-ignore lint/suspicious/noExplicitAny: fixme
  payload: Record<string, any>,
  issuer: string,
  audience: string,
  secret: string,
  expiration: string,
) {
  const secretE = new TextEncoder().encode(secret);
  const alg = 'HS256';

  const jwt = await new jose.SignJWT(payload)
    .setProtectedHeader({ alg })
    .setIssuedAt()
    .setIssuer(issuer)
    .setAudience(audience)
    .setExpirationTime(expiration)
    .sign(secretE);

  return jwt;
}

function str2ab(str: string) {
  const buffer = new ArrayBuffer(str.length * 2);
  const bufView = new Uint16Array(buffer);
  for (let i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buffer;
}

export async function signJWTRS256(
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  claim: Record<string, any>,
  issuer: string,
  audience: string,
  secret: jose.JWK,
  expiration: string,
) {
  const secretE = await jose.importJWK(secret, 'RS256');

  const alg = 'RS256';

  const jwt = await new jose.SignJWT(claim)
    .setProtectedHeader({ alg })
    .setIssuedAt()
    .setIssuer(issuer)
    .setAudience(audience)
    .setExpirationTime(expiration)
    .sign(secretE);

  return jwt;
}

export async function verifyJWTRS256(
  jwt: string,
  secret: jose.JWK,
  options?: JWTVerifyOptions,
) {
  const secretE = await jose.importJWK(secret, 'RS256');
  return await jose.jwtVerify(jwt, secretE, options);
}

export async function createRSAJWK() {
  const { publicKey, privateKey } = await jose.generateKeyPair('RS256');
  const jwkPublic = await jose.exportJWK(publicKey);
  const jwkPrivate = await jose.exportJWK(privateKey);
  const kid = crypto?.randomUUID();
  const props = {
    use: 'sig',
    kid,
    alg: 'RS256',
  };

  return {
    jwkPublic: { ...jwkPublic, ...props },
    jwkPrivate: { ...jwkPrivate, ...props },
  };
}

export async function verifyJWT(
  jwt: string,
  secret: string,
  options?: JWTVerifyOptions,
) {
  const secretE = new TextEncoder().encode(secret);
  return await jose.jwtVerify(jwt, secretE, options);
}
