export function safeJSONStringify(obj: unknown) {
  const cache = new WeakSet();
  return JSON.stringify(obj, (_key, value) =>
    typeof value === 'object' && value !== null
      ? cache.has(value)
        ? undefined
        : cache.add(value) && value
      : value,
  );
}

/**
 * Tries to cast a value to string. Return undefined if parameter is undefined or null
 * @param anything Value to be casted to string
 * @returns String version or undefined if parameter is specifically undefined or null
 */
export function StringOpt(anything: unknown) {
  return String(anything ?? '') || undefined;
}
