import * as E from 'fp-ts/lib/Either';
import { InputValidationError } from './validators';

export const regexCNPJ = /^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/;

export function validateCNPJ(value: string | number | number[] = '') {
  if (!value) {
    return E.left(new InputValidationError('CNPJ is required'));
  }
  const isString = typeof value === 'string';
  const validTypes =
    isString || Number.isInteger(value) || Array.isArray(value);

  if (!validTypes) {
    return E.left(
      new InputValidationError('CNPJ must be a string, number or array'),
    );
  }

  if (isString) {
    const digitsOnly = /^\d{14}$/.test(value);
    const validFormat = regexCNPJ.test(value);
    const isValid = digitsOnly || validFormat;
    if (!isValid) {
      return E.left(new InputValidationError('CNPJ is invalid'));
    }
  }
  const numbers = matchNumbers(value);
  if (numbers.length !== 14) {
    return E.left(new InputValidationError('CNPJ must have 14 digits'));
  }
  const items = [...new Set(numbers)];
  if (items.length === 1) {
    return E.left(new InputValidationError('CNPJ is invalid'));
  }
  const digits = numbers.slice(12);
  const digit0 = validCalc(12, numbers);
  if (digit0 !== digits[0]) {
    return E.left(new InputValidationError('CNPJ is invalid'));
  }
  const digit1 = validCalc(13, numbers);
  return digit1 === digits[1]
    ? E.right(numbers.join(''))
    : E.left(new InputValidationError('CNPJ is invalid'));
}

export function formatCNPJ(value: string | number | number[] = '') {
  const valid = validateCNPJ(value);
  if (!valid) {
    return E.left(new InputValidationError('CNPJ is invalid'));
  }

  const numbers = matchNumbers(value);
  const text = numbers.join('');

  const format = text.replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
    '$1.$2.$3/$4-$5',
  );

  return E.right(format);
}

function validCalc(x: number, numbers: number[]) {
  const slice = numbers.slice(0, x);
  let factor = x - 7;
  let sum = 0;
  for (let i = x; i >= 1; i--) {
    const n = slice[x - i];
    sum += n * factor--;
    if (factor < 2) factor = 9;
  }
  const result = 11 - (sum % 11);
  return result > 9 ? 0 : result;
}

function matchNumbers(value: string | number | number[] = '') {
  const match = value.toString().match(/\d/g);
  return Array.isArray(match) ? match.map(Number) : [];
}
