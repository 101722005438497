import { safeJSONStringify } from './stringify';

/** @deprecated JSONRequest instead */
export class JsonRequest<T> extends Request {
  json(): Promise<T> {
    return super.json();
  }
}

export class JSONRequest<T> extends Request {
  json(): Promise<T> {
    return super.json();
  }
}

/** @deprecated Use JSONResponse instead */
export class JsonResponse<T> extends Response {
  constructor(body: T, init?: ResponseInit) {
    const headers = new Headers(init?.headers);
    headers.append('Content-Type', 'application/json');
    super(safeJSONStringify(body), { ...init, headers });
  }
}

export function getHeaders(
  headers?: Record<string, string>,
  cookies?: Record<string, string>,
): Record<string, string> {
  const Cookie = cookies
    ? Object.entries(cookies)
        .map(([k, v]) => `${k}=${v}`)
        .join(';')
    : '';
  return {
    ...(headers ?? {}),
    Cookie,
  };
}

export function headersToRecord(headers: Headers): Record<string, string> {
  return Array.from(headers.entries()).reduce<Record<string, string>>(
    (acc, [key, val]) => ({ ...acc, [key]: val }),
    {},
  );
}

export type HTTPMethod =
  | 'GET'
  | 'POST'
  | 'PUT'
  | 'PATCH'
  | 'DELETE'
  | 'OPTIONS'
  | 'HEAD'
  | 'CONNECT'
  | 'TRACE';

export const JSONResponses = {
  Unauthorized() {
    return new JsonResponse(
      {
        success: false,
        error: {
          message: 'Unauthorized',
        },
      },
      { status: 401 },
    );
  },

  NotFound() {
    return new JsonResponse(
      {
        success: false,
        error: {
          message: 'Object not found',
        },
      },
      { status: 404 },
    );
  },

  MethodNotAllowed(allowed: HTTPMethod[]) {
    // see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods for more methods
    return new JsonResponse(
      {
        success: false,
        error: {
          message: 'Method Not Allowed',
        },
      },
      {
        status: 405,
        headers: { Allow: allowed.join(', ') },
      },
    );
  },

  Forbidden(error?: unknown) {
    return new JsonResponse(
      {
        success: false,
        error: error ?? {
          message: 'Forbidden',
        },
      },
      { status: 403 },
    );
  },
};

class _JSONResponseImpl<T> extends Response {
  constructor(body: T, init?: ResponseInit) {
    super();
    // biome-ignore lint/correctness/noConstructorReturn:
    return Response.json(body, init);
  }
}

export type JSONResponse<T> = Response & typeof _JSONResponseImpl<T>;

export function JSONResponse<T>(body: T, init?: ResponseInit) {
  return new _JSONResponseImpl<T>(body, init) as unknown as JSONResponse<T>;
}
