/* eslint-disable */

const formatThousandsRegExp = /\B(?=(\d{3})+(?!\d))/g;
const formatDecimalsRegExp = /(?:\.0*|(\.[^0]+)0+)$/;
const map: any = {
  b: 1,
  kb: 1 << 10,
  mb: 1 << 20,
  gb: 1 << 30,
  tb: 1024 ** 4,
  pb: 1024 ** 5,
};
const parseRegExp = /^((-|\+)?(\d+(?:\.\d+)?)) *(kb|mb|gb|tb|pb)$/i;

export function formatBytes(value?: any, options?: any): any {
  if (typeof value === 'string') {
    return parse(value);
  }
  if (typeof value === 'number') {
    return format(value, options);
  }
  return null;
}

function format(value: any, options: any) {
  if (!Number.isFinite(value)) {
    return null;
  }
  const mag = Math.abs(value);
  const thousandsSeparator = options?.thousandsSeparator || '';
  const unitSeparator = options?.unitSeparator || '';
  const decimalPlaces =
    options && options.decimalPlaces !== undefined ? options.decimalPlaces : 2;
  const fixedDecimals = Boolean(options?.fixedDecimals);
  let unit = options?.unit || '';
  const unitLower = unit.toLowerCase();
  const uniStr = map[unitLower];
  if (!unit || !uniStr) {
    if (mag >= map.pb) {
      unit = 'PB';
    } else if (mag >= map.tb) {
      unit = 'TB';
    } else if (mag >= map.gb) {
      unit = 'GB';
    } else if (mag >= map.mb) {
      unit = 'MB';
    } else if (mag >= map.kb) {
      unit = 'KB';
    } else {
      unit = 'B';
    }
  }
  const val = value / uniStr;
  let str = val.toFixed(decimalPlaces);
  if (!fixedDecimals) {
    str = str.replace(formatDecimalsRegExp, '$1');
  }
  if (thousandsSeparator) {
    str = str
      .split('.')
      .map((s, i) =>
        i === 0 ? s.replace(formatThousandsRegExp, thousandsSeparator) : s,
      )
      .join('.');
  }
  return str + unitSeparator + unit;
}

function parse(val: any) {
  if (typeof val === 'number' && !Number.isNaN(val)) {
    return val;
  }
  if (typeof val !== 'string') {
    return null;
  }
  const results = parseRegExp.exec(val);
  let floatValue;
  let unit;
  if (!results) {
    floatValue = Number.parseInt(val, 10);
    unit = 'b';
  } else {
    floatValue = Number.parseFloat(results[1]);
    unit = results[4].toLowerCase();
  }
  if (Number.isNaN(floatValue)) {
    return null;
  }
  return Math.floor(map[unit] * floatValue);
}
