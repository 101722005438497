export function normalizePhone(phone: string): string {
  return phone.replace(/[^\d+]/g, '');
}

export function removeCountryCodePhone(
  phone: string,
  countryCode: string,
): string {
  const normalizedPhone = normalizePhone(phone);
  const reg = new RegExp(`^(\\+${countryCode}|${countryCode})`);
  return normalizedPhone.replace(reg, '');
}

export function validateMobilePhone(phone: string): boolean {
  return normalizePhone(phone).length === 14;
}
