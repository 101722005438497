// TODO: unit test this

export function validateCPF(cpf: string): boolean {
  if (!cpf) return false;

  const REGX = /[^\d]+/g;
  const parsed = cpf.replace(REGX, '');

  if (parsed.split('').every((v) => v === parsed[0]) || parsed.length !== 11)
    return false;

  return [
    [9, 11],
    [10, 12],
  ].reduce((_res, [limit, digit]) => {
    let sum = 0;
    for (let i = 1; i <= limit; i++)
      sum = sum + Number.parseInt(parsed[i - 1]) * (digit - i);
    let mod = (sum * 10) % 11;

    if (mod === 10 || mod === 11) mod = 0;

    if (mod !== Number.parseInt(parsed.substring(limit, limit + 1)))
      return false;
    return true;
  }, false);
}

// Validação baseada nas regras de verificação SSP-SP
export function validateRG(rg: string): boolean {
  if (!rg) return false;

  const REGX = /[^\dxX]+/g;
  const parsed = rg.replace(REGX, '');

  if (parsed.length !== 9) return false;

  function getVerificationDigit(value: string): string {
    const digits = value.substring(0, 8).split('');

    const sums = digits.map((n, i) => Number(n) * (2 + i));
    const total = sums.reduce((acc, cur) => acc + cur, 0);
    const mod = total % 11;

    const result = 11 - mod;

    if (result === 10) return 'x';
    return String(result);
  }

  const vDigit = getVerificationDigit(parsed);

  if (parsed[parsed.length - 1].toLowerCase() !== vDigit) return false;
  return true;
}

export function validateCNPJ2(cnpj: string): boolean {
  if (!cnpj) return false;
  const REGX = /[^\d]+/g;
  const parsed = cnpj.replace(REGX, '');

  const getVerificationDigit = (sum: number): number =>
    sum % 11 < 2 ? 0 : 11 - (sum % 11);

  if (parsed.split('').every((v) => v === parsed[0]) || parsed.length !== 14)
    return false;

  // Valida DVs
  let main = parsed.substring(0, 12);
  const digits = parsed.substring(12);

  let sum1 = 0;
  let pos1 = 12 - 7;
  for (let i = 12; i >= 1; i--) {
    sum1 += Number.parseInt(main.charAt(12 - i)) * pos1--;
    if (pos1 < 2) pos1 = 9;
  }

  const vDigit1 = getVerificationDigit(sum1);
  if (vDigit1 !== Number.parseInt(digits.charAt(0))) return false;

  main = parsed.substring(0, 13);
  let sum2 = 0;
  let pos2 = 13 - 7;
  for (let i = 13; i >= 1; i--) {
    sum2 += Number.parseInt(main.charAt(13 - i)) * pos2--;
    if (pos2 < 2) pos2 = 9;
  }
  const vDigit2 = getVerificationDigit(sum2);
  if (vDigit2 !== Number.parseInt(digits.charAt(1))) return false;

  return true;
}

export function normalizeCPF(cpf: string): string {
  return cpf.replace(/[^\d]+/g, '');
}

/**
 * Adds pontuaction pattern used for MedSimples Shift manager add-doctor
 * @param cep
 * @returns
 */
export function addPonctuationToCEP(cep: string) {
  if (cep?.length !== 8) throw new Error('Invalid raw CEP given!');
  return cep.replace(/^(\d{5})(\d{3}).*/, '$1-$2');
}

/**
 * Adds pontuaction pattern used for MedSimples Shift manager add-doctor
 * @param cpf
 * @returns
 */
export function addPonctuationToCPF(cpf: string) {
  const reg = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
  if (cpf?.match(reg)) return cpf;

  if (!cpf?.match(/^\d{11}$/)) throw new Error('Invalid raw CPF given!');

  return cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4');
}

/**
 * Adds pontuaction pattern used for MedSimples Shift manager add-doctor
 * @param cnpj
 * @returns
 */
export function addPonctuationToCNPJ(cnpj: string) {
  if (cnpj.length !== 14) throw new Error('Invalid raw CNPJ given!');
  return cnpj.replace(
    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/,
    '$1.$2.$3/$4-$5',
  );
}

/**
 * Adds pontuaction pattern used for MedSimples Shift manager add-doctor
 * @param phone
 * @returns
 */
export function addPonctuationToPhone(phone: string) {
  // +XX XX-XXXXX-XXXX
  if (![13, 14].includes(phone.length) || !phone.startsWith('+55'))
    throw new Error(
      'Invalid raw phone given! Expected format is "+5511999999999".',
    );
  const firstPartLength = phone.length - 9;
  const regex = new RegExp(
    `^(\\+\\d{2})(\\d{2})(\\d{${firstPartLength}})(\\d{4}).*`,
  );
  return phone.replace(regex, '$1 $2-$3-$4');
}

/**
 * Use Intl API to format to BRL currency
 * @param value
 * @returns Formatted currency string
 * @example
 * formatBRL(25); // "R$ 25,00"
 *
 */
export const formatBRL = Intl.NumberFormat('pt-br', {
  style: 'currency',
  currency: 'BRL',
}).format;

export const parseDateTimeBR = (dateTime: string, formatWithDash?: boolean) => {
  const time = new Date(dateTime);
  time.setHours(time.getHours() - time.getTimezoneOffset() / 60);

  if (formatWithDash)
    return `${time.toLocaleString('pt-BR')}`.replace(',', ' -').slice(0, -3);

  return `${time.toLocaleString('pt-BR')}`;
};

export const brazilianStates = [
  'ACRE',
  'ALAGOAS',
  'AMAPÁ',
  'AMAZONAS',
  'BAHIA',
  'CEARÁ',
  'DISTRITO FEDERAL',
  'ESPÍRITO SANTO',
  'GOIÁS',
  'MARANHÃO',
  'MATO GROSSO',
  'MATO GROSSO DO SUL',
  'MINAS GERAIS',
  'PARÁ',
  'PARAÍBA',
  'PARANÁ',
  'PERNAMBUCO',
  'PIAUÍ',
  'RIO DE JANEIRO',
  'RIO GRANDE DO NORTE',
  'RIO GRANDE DO SUL',
  'RONDÔNIA',
  'RORAIMA',
  'SANTA CATARINA',
  'SÃO PAULO',
  'SERGIPE',
  'TOCANTINS',
];

export const brazilianStateCapitals: { [key: string]: string } = {
  ACRE: 'RIO BRANCO',
  ALAGOAS: 'MACEIÓ',
  AMAPÁ: 'MACAPÁ',
  AMAZONAS: 'MANAUS',
  BAHIA: 'SALVADOR',
  CEARÁ: 'FORTALEZA',
  'DISTRITO FEDERAL': 'BRASÍLIA',
  'ESPÍRITO SANTO': 'VITÓRIA',
  GOIÁS: 'GOIÂNIA',
  MARANHÃO: 'SÃO LUÍS',
  'MATO GROSSO': 'CUIABÁ',
  'MATO GROSSO DO SUL': 'CAMPO GRANDE',
  'MINAS GERAIS': 'BELO HORIZONTE',
  PARÁ: 'BELÉM',
  PARAÍBA: 'JOÃO PESSOA',
  PARANÁ: 'CURITIBA',
  PERNAMBUCO: 'RECIFE',
  PIAUÍ: 'TERESINA',
  'RIO DE JANEIRO': 'RIO DE JANEIRO',
  'RIO GRANDE DO NORTE': 'NATAL',
  'RIO GRANDE DO SUL': 'PORTO ALEGRE',
  RONDÔNIA: 'PORTO VELHO',
  RORAIMA: 'BOA VISTA',
  'SANTA CATARINA': 'FLORIANÓPOLIS',
  'SÃO PAULO': 'SÃO PAULO',
  SERGIPE: 'ARACAJU',
  TOCANTINS: 'PALMAS',
};
